body {
    margin: 0;
    font-family: 'Roboto', sans-serif; /* Use a Google Font or a font of your choice */
    background-color: #f0f0f0; /* Set a light background color */
  }
  
  .background {
    background-image: url("../../Images/dentist-examining-patient-s-teeth.jpg");
    background-size: cover;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form-container {
    background-color: #61dafb63;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 20px #ffffff;
    height: auto; /* Add a subtle box shadow */
  }
  
  form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }
  
  h2 {
    color: #333;
  }
  
  label {
    margin: 13px 5px;
    font-weight: bold;
    color: #555;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  button.button-002 {
    background-color: #262688;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0d47a1; /* Darker shade on hover */
  }
  .con-background{
    background-image: url("../../Images/003.jpg");
    background-size: cover;
    height: 140vh;
    display: flex;
    align-items: center;
    position: relative;
    top: 3rem;
    /* justify-content: center; */
  }

  /* Optional: Add responsive styles */
  @media screen and (max-width: 600px) {
    .form-container {
      width: 80%;
    }
  }
  .map iframe{
    width: 100%;
    height:400px;
    border:0;
  }
/* Media query for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .map iframe {
      width: 100%; /* Adjust width to fit the screen */
      height: 300px; /* Adjust height as needed */
  }
}
