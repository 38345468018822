.Home-Main {
    background: rgb(101,38,208);
    background: linear-gradient(90deg, rgba(101,38,208,1) 0%, rgba(143,38,208,1) 100%);
    border-radius: 0px 0px 250px 0px;
}
a,
span {
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
}
.cover {
    border-bottom-right-radius: 256px;
}
.facility {
  min-height: 100vh;
}
.heading-001 {
  width: 40rem;
}
.shadowBox{
  --tw-shadow: 0 10px 15px -3px rgb(134 46 148), 0 4px 6px -4px #8600ff;
}
.social-icon{
  display: flex;
    justify-content: center;
}
.footer-head{
  padding-left: 5rem;
  padding-right: 5rem;
}
.heading-facility{
  font-size: 2rem;
}

  .btn-14 {
    background: rgb(255, 151, 0);
    border: 2px solid rgba(255, 255, 255, 0.402);
    border-radius: 20px;
    width: 15vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4rem;
    left: 5rem;
  }
  /* .btn-14:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #eaf818;
    background-image: linear-gradient(315deg, #eaf818 0%, #f6fc9c 74%);
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5);
    transition: all 0.3s ease;
  }
  .btn-14:hover {
    color: #000;
  }
  .btn-14:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
  } */

.facility .box-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 3rem;
}

.facility .box-container .box {
  height: 20rem;
  width: 30rem;
  margin: 0.3rem;
  overflow: hidden;
}

.facility .box-container .box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.facility .box-container .box img {
  transition: transform 0.3s ease-in-out; /* Adjust the duration and easing function as needed */
}

.facility .box-container .box:hover img {
  transform: scale(1.3);
}
.dropdown-content {
  display: none;
  width: 150px;
  position: absolute;
  z-index: 1;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  background-color: #212121;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-content a {
  color: #ffffff;
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  transition: 0.1s;
}



.dropdown-content a:focus {
  background-color: #212121;
  color: #ffffff;
}


.paste-button:hover .dropdown-content {
  display: block;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f2f2f2;
  box-shadow: 0 0.125rem 0.125rem -0.125rem rgba(60, 60, 61, 0.08), 0 0.25rem 0.75rem rgba(31, 27, 45, .08);
  border-radius: 10px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 4;
}

.dropdown-content a {
  color: rgb(3, 3, 3);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content a:hover {
  background-color: rgb(217, 217, 217);
  border-radius: 1rem;
}


.facility{
  min-height: 100vh;
}

.facility .box-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 3rem;
}

.facility .box-container .box{
  height:20rem;
  width:30rem;
  margin:.3rem;
  overflow: hidden;
}

.facility .box-container .box img{
  height:100%;
  width:100%;
  object-fit: cover;
}
.right-container{
  margin: 0;
  height: 30vh;
  width: 100%;
}
.right-container img{
  width: 42vw;
 
  position: relative;
  left: 35rem;
  bottom: 10rem;
}

@media screen and (max-width:469px) {
  .heading-001 {
    width: auto;
  }
  .home-heading  {
    justify-content: center;
  }
  .btn-14 {
    width: 34vw;
  }
}
.left-container{
  display: flex;
 
}

.footer01 img{
  width: 10vw;
  position: relative;
  left: 6.2rem;
}

@media (max-width: 768px) {
  #menu {
    /* display: none; */
    backdrop-filter: blur(10px) ;
  }
  .btn-14 {
    width: 30vw;
  }
  .heading-001 {
    width: 30rem;
}
}
@media (max-width: 390px){
  .heading-001 {
    width: 20rem;
}
}