
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* body{
  background: rgb(138,6,221);
  background: linear-gradient(328deg, rgb(205, 135, 248) 0%, rgb(151, 128, 176) 6%, rgb(233, 225, 236) 100%);
} */
.about-para p {
  font-family: "Nunito Sans", sans-serif;
}
.photo-container{
  /* w-full h-auto flex justify-center items-center */
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

}
.photo-slide-container {
  position: relative;
  width: 700px;
  height: 400px;
  margin: 100px auto;
  background-image: url('../../Images/002.jpg');
  background-size: 700px 400px;
  box-shadow: 0px 20px 30px rgb(203, 158, 231);
  border: 5px solid #cfb1ec;
}
.photo-slide {
  position: absolute;
  top: 0;
  left: 0;
  filter: sepia(100%);
  height: 100%;
  width: 50%;
  max-width: 100%;
  resize: horizontal;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  background-image: url('../../Images/001.jpg');
  background-size: 700px 400px;
  border: 2px solid #ff0404;
  animation-delay: 0.5s;
  animation-duration: 10s;
  animation-name: ani;
}
.photo-slide:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 13px;
  height: 13px;
  padding: 5px;
  background: linear-gradient(-45deg, #262626 50%, transparent 0);
  cursor: ew-resize;
}
@keyframes ani {
  0% {
    width: 10%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 50%;
  }
}


@media screen and (max-width:469px) {
  .container {
    flex-direction: column;
  }
  .left-about {
    width: 90%;
  }
  .right-about {
    width: 90%;
  }
  .photo-slide-container{

    flex-direction: column;
  }
  
}

@media (max-width: 469px) {
  .photo-slide-container {
    width: 90%;
    max-width: 400px;
    margin: 50px auto;
    height: auto;
  }
  
  .photo-slide {
    width: 100%;
    max-width: 100%;
    height: auto;
    resize: none;
    background-size: cover;
  }
  .photo-container{
    display: none;
  }
}